/* changing logo */
#logo {
  width: 100%;
  height: auto;
}
* {
  margin: 0;
  cursor: default;
}

#data_status{
  visibility: hidden;
}

#container {
  width: 1300px;
  height: 70px;
  margin:0 auto;
}

/* changing headers: these are filters and sort */
#filterstable{
  overflow-x: hidden;
  text-align: left;
}

#error_message{
  width: 120px;
  border-style:double;
  border-color: darkred;
  color: darkred;
  background-color: #f4c7c7;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: oblique;
}

#sortby {
  color: #2b73b4;
  font-size:xx-large;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  left: 0px;
  top: 0px;
  display: inline;
} 

.filters_column{
  width: 300px;
  overflow: hidden;
  /* background-color: aqua; */
}

#filters {
  color: #2b73b4;
  font-size:xx-large;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  left:  0px;
  display: inline;
}


/* this is the dropdown part of sort by */
#sort {
  position: relative;
  height: 30px;
  width: 160px;
  background-color: #f2f7fa;
  left: 0px;
  top: 2px;
} 

#companies {
  color: rgb(0, 35, 44);
  font-size:x-large;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  left: 0px;
  text-decoration: underline;
}

#start_ports{
  color: rgb(0, 35, 44);
  font-size:x-large;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  left: 0px;
  text-decoration: underline;
}

#rate {
  color: rgb(0, 35, 44);
  font-size:x-large;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  left: 0px;
  text-decoration: underline;
}

.checkbox_div{
  display: block;
  height: 78px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 250px;
}

.checkbox_div_small{
  display: block;
  height: 78px;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 140px;
}

#ship_checkbox {
  position: relative;
  left: 0px;
  top: 0px;
} 

#brand_box{
  position: relative;
  left: 10px;
}

#checkbox_rate {
  position: relative;
  left: 0px;
  top: -27px;
}

#rate_box {
  position: relative;
  left: 10px;
}

#min_dur_col{
  width: 180px;
  text-align: left;
}

#max_dur_col{
  width: 230px;
  text-align: left;
}

#min_date_col{
  width: 280px;
  text-align: left;
}

#max_date_col{
  width: 260px;
  text-align: left;
}

.giant_buffer{
  width: 300px;
  text-align: center;
}

#date_durration_container{
  width: 1300px;
  height: 30px;
  margin:0 auto;
  position: relative;
  top: 10px;
  left: 5px;
}

#min_duration{
  position: relative;
  left: 0px;
  top: 0px;
  font-weight:300;
  font-size: large;
}

#min_duration_input{
  position: relative;
  left: 7px;
  top: -2px;
  width: 30px;
  height: 15px;
  background-color: lightblue;
  font-weight: bold;
  border: none;
  }

#max_duration{
  position: relative;
  left: 0px;
  top: 0px;
  font-weight:300;
  font-size: large;
}

#max_duration_input{
  position: relative;
  left: 7px;
  top: -2px;
  width: 30px;
  height: 15px;
  background-color: rgb(191, 226, 237);
  font-weight: bold;
  border: none;
}

#min_date{
  font-weight:300;
  font-size: large;
  position: relative;
  left: 0px;
  top: 0px;
}

#min_date_input{
  position: relative;
  left: 5px;
  background-color: rgb(191, 226, 237);
  top: -2px;
  height: 15px;
  max-width: 115px;
  font-weight: bold;
  border: none;
}
  
#max_date{
  position: relative;
  left: 0px;
  font-weight:300;
  font-size: large;
  top: 0px;
}

#max_date_input{
  position: relative;
  left: 5px;
  background-color: lightblue;
  top: -2px;
  height: 15px;
  max-width: 115px;
  font-weight: bold;
  border: none;
}


#merged_col{
  display: block;
  width: 200px;
  max-height: 50px;
  overflow-x: visible;
}

#hide_cruise_text{
  padding-bottom: 3px;
}

.hide_box {
  position: relative;
  left: 10px;
}

.nightly_box {
  position: relative;
  left: 10px;
}

#about_link{
  width: 35px;
  height: 20px;
  border-width: 3px;
  border-style: double;
  border-radius: 3px;
  background-color: rgb(207, 229, 236);
  border-color: #2b73b4;
  position: relative;
  left: -15px;
  text-align: center;
}


#link_text{
  color: #1f598b;
}

/* get more data button */
#get_data_container{
  width: 1300px;
  height: 40px;
  margin:0 auto;
  position: relative;
  top: 90px;
}

.get_data {
  background-color: #438ed2;
  position: absolute;
  left: 47%;
  top: 6%;
  height: 30px;
  width: 120px;
  font-weight: bolder; 
}

.get_data_div{
  width: 1300px;
}

/* formating table */
#table_container {
  position: relative;
  top: 90px;
  width: 1300px;
  margin:0 auto;
}

#table-headers {
  background-color: #438ed2;
  color: black;
  font-size:large;
  font-family:Arial, Helvetica, sans-serif;
  font: bold;
  font-weight:300;
  border: none;
  position: -webkit-sticky; /* makes it stay when you scroll */
    position: sticky;
    top: 0;
    z-index: 1; 
}

.maintable {
  text-align: center;
  font-weight:300;
}

#itinerary_header{
  text-align: center;
  width: 200px;
}

@font-face {
  font-family: StyleFont;
  src: url(Carattere-Regular.ttf);
}

#info_element{
  padding-bottom: 10px; 
}

#brand_name{
  font-family: StyleFont;
  font-size: 25pt;
  padding-top: 7px;
}

#itinerary{
  text-align: left;
  width: 200px;
}

#inside{
  width: 170px;
}

#ocean_view{
  width: 170px;
}

#balcony{
  width: 170px;
}

#mini_suite{
  width: 170px;
}

#suite{
  width: 170px;
}

#info_head {
  width: 200px;
}

#num_nights{
  width: 70px;
}

.total_price{
  font-weight: 500;
  padding-top: 2px;
}

/* hover over icons */
.icon_hover {
  position: relative;
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
  top: -2px;
  height: 7px;
}

.icon_hover .icon_text {
  visibility: hidden;
  background-color: lightblue;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  width: 120px;
  position: relative;
  top: 20px;
  left: 50%;
  margin-left: -60px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.icon_hover:hover .icon_text {
  visibility: visible;
  animation: tooltipkeys .75s 1;
  opacity: 1;
}

#credit{
  padding-top: 4px;
  padding-bottom: -10px;
  position: relative;
  top: 3px; 
}

#icons{
  padding-top: 4px;
  padding-bottom: 1px;
}

#credit_amount{
  position: relative;
  top: -4px;
  font-size: .37cm;
}

#credit_test{
  position: relative;
  top: -.5px;
}


/* this makes the text for the icons not apear right away */
@-webkit-keyframes tooltipkeys {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes tooltipkeys {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes tooltipkeys {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tooltipkeys {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.expanded_table_element{
  max-height: 225px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* changing every other row to colored */
table.maintable tr:nth-child(even) {
  background-color: rgb(236, 236, 236);
}

/* expanded row color LIGHT */
table.maintable tr.colored_element{
  background-color: rgb(255, 244, 240);
  /* height: 225px; */
}

/* expanded row color DARK */
table.maintable tr.colored_element:nth-child(even) {
  background-color: rgb(246, 223, 215);
  /* height: 225px; */
}

ul {
  /*space between prices */
  padding-bottom: 15px;
  padding-top: 15px;
  /* center this ignoring the bullet points */
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  
}


/* THIS IS CSS FOR INFO PAGE */
.back_button{
  padding-left: 10px;
  padding-right: 10px;
  border-width: 3px;
  border-style: double;
  border-radius: 3px;
  background-color: rgb(207, 229, 236);
  border-color: #2b73b4;
  text-align: center;
  position: relative;
  left: 20px;
  top: 10px;
  font-size: large;
  color: #174064;
}

.back_button:link { 
  text-decoration: none; 
}
.back_button:visited { 
  text-decoration: none; 
}
.back_button:hover { 
  text-decoration: none; 
}
.back_button:active { 
  text-decoration: none; 
}

#container_info {
  width: 800px;
  height: 80px;
  margin:0 auto;
}

#info_title {
  color: #185e9a;
  font-size: 45px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wheel {
  color: #185e9a;
  font-size: 60px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  top: 15px;
}

.info_text {
  font-weight: 500;
  line-height: 2;
  padding-bottom: 20px;
}

#image_text{
  text-align: right;
  position: relative;
  left: 50px;
}

#email_text{
  text-align: center;
  color: #174064;
}

#pic{
  width: 900px;
  position: relative;
  left: -45px;
  padding-top: 5px;
}
